import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import AndroidLogo from "../../images/Careers/deeplai-career-android-developer.svg";

const CareersAndroidDevelopers = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.android) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (

        <OfferPage
            image={AndroidLogo}
            imageAlt={'deeplai career android developer'}
            name={routes.CareersAndroidDeveloper.pageTitle}
            scopeOfDuties={<>
                <p>software implementation by specification</p>
                <p>active participation in the creation of the concept and design of software</p>
                <p>cooperation with other teams - testers, designers, etc ...</p>
                <p>cooperation in implementing and maintaining application with customers</p>
            </>}
            ourRequirements={<>
                <p>experience in Kotlin (basics of Java) and the Android SDK</p>
                <p>knowledge of aspects related to running AI models (TensorFlowLite) on Android devices</p>
                <p>knowledge of client-server communication issues, UX/ UI principles for mobile platforms</p>
                <p>knowledge of software development methodologies, ability to work in a team</p>
                <p>at least minimal knowledge of ARcore</p>
                <p>familiarity with real-time camera capture images processing</p>
                <p>familiarity with app development and app management in GooglePlay</p>
                <p>basic knowledge of English</p>
            </>}
            whatYouCanExpectFromUs={<>
                <p>working in a team inspired and fascinated by AI & Computer Vision
                technologies</p>
                <p>friendly atmosphere, daily cooperation with open minded people</p>
                <p>various, interesting projects in cutting-edge technologies</p>
                <p>real personal impact on implemented projects</p>
                <p>renumeration package adequate to your experience</p>
                <p>hybrid/flexible working hours</p>
            </>}
        />
    );
};

export default CareersAndroidDevelopers;
